import ScrollReveal from 'scrollreveal';

document.addEventListener("DOMContentLoaded", initLayout);

function initLayout() {
    const slideInFromLeft = {
        distance: '150%',
        origin: 'left',
        opacity: null,
        interval: 200
    };
    ScrollReveal().reveal('.slide-in-from-left p', slideInFromLeft);
    ScrollReveal().reveal('.slide-in-from-left h1', slideInFromLeft);
    ScrollReveal().reveal('.slide-in-from-left h2', slideInFromLeft);
    ScrollReveal().reveal('.slide-in-from-left h3', slideInFromLeft);
    ScrollReveal().reveal('.slide-in-from-left li', slideInFromLeft);

    initFooterCollapse();
}

function initFooterCollapse() {
    $('#footer-collapse').on('shown.bs.collapse', function(event){
        event.target.scrollIntoView();
    });
}